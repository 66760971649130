import React, { useMemo } from "react";

import "./LoadingBar.sass"

export const LoadingBar = ({className = ""}) => {
    const props = useMemo(() => ({
        className: `loading-bar${className?" "+className:""}`
    }), [className]);
    return (
        <div {...props}>
            <div className="intermediate"></div>
        </div>
    );
}