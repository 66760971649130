import React, { useMemo } from "react";
import { localText } from "../utils/localization";
import "./LoadingPulse.sass";

export const LoadingPulse = ({inline = false}) => {
    const {
        boxPs
    } = useMemo(() => {
        return {
            boxPs: {
                className: "loading-box d-flex justify-content-center align-items-center" + (inline ? "loading-box-inline" : "")
            }
        }
    },[inline]);
    return (
        <div {...boxPs}>
            <div className="lds-ripple"><div></div><div></div><span className="visually-hidden">{localText.loading}</span></div>
        </div>
    )
};