import React, { lazy } from "react";

export enum Icons {
    Activity,
    BottleFeeding,
    BreastFeeding,
    BreastFeedingAlt,
    Diaper,
    DiaperDry,
    DiaperSoiled,
    DiaperSoiledWet,
    DiaperWet,
    Pumping,
    Supplement,
    Feeding,
    Journal,
    FaceHappy,
    FaceNeutral,
    FaceSad,
    FaceOther,
    FacePainMild,
    FacePainModerate,
    FacePainSevere,
    FacePainSlight,
    FacePainStrong,
    Foot,
    Feet,
    Stopwatch,
    Pregnant,
    Postpartum
};

export const IconComponents : { [key in SvgIconType]: React.LazyExoticComponent<React.ComponentType<any>> } = {
    Activity: lazy(() => import("jsx:./activity.svg")),
    BottleFeeding: lazy(() => import("jsx:./bottle-feeding.svg")),
    BreastFeeding: lazy(() => import("jsx:./breast-feeding.svg")),
    BreastFeedingAlt: lazy(() => import("jsx:./breast-feeding-alt.svg")),
    Diaper: lazy(() => import("jsx:./diaper.svg")),
    DiaperDry: lazy(() => import("jsx:./diaper-dry.svg")),
    DiaperSoiled: lazy(() => import("jsx:./diaper-soiled.svg")),
    DiaperSoiledWet: lazy(() => import("jsx:./diaper-soiledandwet.svg")),
    DiaperWet: lazy(() => import("jsx:./diaper-wet.svg")),
    Pumping: lazy(() => import("jsx:./pumping.svg")),
    Supplement: lazy(() => import("jsx:./supplement.svg")),
    Feeding: lazy(() => import("jsx:./feeding.svg")),
    Journal: lazy(() => import("jsx:./journal.svg")),
    FaceHappy: lazy(() => import("jsx:./excited-happy.svg")),
    FaceNeutral: lazy(() => import("jsx:./relaxed-neutral.svg")),
    FaceSad: lazy(() => import("jsx:./sad-emotional.svg")),
    FaceOther: lazy(() => import("jsx:./other-emotion.svg")),
    FacePainMild: lazy(() => import("jsx:./face-pain-mild.svg")),
    FacePainModerate: lazy(() => import("jsx:./face-pain-moderate.svg")),
    FacePainSevere: lazy(() => import("jsx:./face-pain-severe.svg")),
    FacePainSlight: lazy(() => import("jsx:./face-pain-slight.svg")),
    FacePainStrong: lazy(() => import("jsx:./face-pain-strong.svg")),
    Foot: lazy(() => import("jsx:./foot.svg")),
    Feet: lazy(() => import("jsx:./feet.svg")),
    Stopwatch: lazy(() => import("jsx:./stopwatch.svg")),
    Pregnant: lazy(() => import("jsx:./pregnant.svg")),
    Postpartum: lazy(() => import("jsx:./postpartum.svg")),
}

export const SvgIcon : SvgIconComponent = ({ icon }) => {

    const Component = icon in IconComponents
    
        ? IconComponents[icon]
        
        : IconComponents[Icons[icon]];

    return <Component />;

}