// Third party integration
export const recharts = () => import("../../integration/recharts");

export const sweetalert = () => import("../../integration/sweetalert");

export const datetime = () => import("../../integration/datetime");

// Internal Modules (For loading segmentation)
export const activityModule = () => import("../../modules/activity");

export const ldContentModule = () => import("../../modules/ldcontent");

export const formModule = () => import("../../modules/form");

export const userstoriesModule = () => import("../../modules/userstories");