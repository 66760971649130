import React, { useEffect } from "react"
import { useLocation } from "react-router-dom";
import { triggerEvent } from "../utils/events";
// import { useLocation } from "react-router-dom";
import { trackViewPage } from "../utils/tracking";

let pagesLoaded = 0;

export const Page = ({
    title,
    children
}) => {

    const location = useLocation();

    useEffect(() => {
        triggerEvent('location-chage', { location });
    }, [location])

    useEffect(() => {
        pagesLoaded++;
        title && (document.title = title);
        if( pagesLoaded > 1 ) {
            trackViewPage();
        }
    }, [title]);

    return <>{children}</>

}