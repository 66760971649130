import { useEffect } from "react";

export const on = (eventType : string, listener) => {
  document.addEventListener(eventType, listener);
}

export const off = (eventType : string, listener) => {
  document.removeEventListener(eventType, listener);
}

export const triggerEvent = ( eventType: string, data = undefined ) => {
  const event = new CustomEvent(eventType, { detail: data });
  document.dispatchEvent(event);
}

export const once = ( eventType: string, listener ) => {
  on(eventType, handleEventOnce);

  function handleEventOnce(event) {
    listener(event);
    off(eventType, handleEventOnce);
  }
}

export const useEventListener = ( eventType: string, listener, watchList : any[] = []) => {
  useEffect(() => {
    on(eventType, listener);

    return () => {
      off(eventType, listener);
    }
  }, watchList)
}

export const useEventListenerOnce = ( eventType: string, listener ) => {
  useEffect(() => {
    once(eventType, listener);

    return () => {
      off(eventType, listener);
    }
  }, []);
}