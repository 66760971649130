import * as library from "./library";

import { moduleCache } from "./async";

export const fetchModules = async ( modules ) => {
    const loadedModules = await Promise.all(modules.map(module => library[module]()));

    // Assist with loading the cache
    for (const i in modules) if(!moduleCache.has(modules[i])) moduleCache.set(modules[i], loadedModules[i]);

    return loadedModules;
};