import React from "react";
import { LoadingPulse } from "./LoadingPulse";

interface ApiSuspenseProps {
    ok: null|boolean,
    context: string,
    element?: undefined|React.ComponentType,
    children?: undefined|JSX.Element
}

export const ApiSuspense = ({
    ok,
    context,
    element: Component = undefined,
    children = undefined
}: ApiSuspenseProps) => {

    const isPending = ok === null;

    return (
        <>    
            {isPending ? <LoadingPulse /> : (ok ? (
                Component ? <Component /> : <>{children}</>
            ) : (
                <div className="alert alert-danger">Oh no! Looks like we couldn't load {context}.</div>
            ))}
        </>
    );

}